#singlepost {

    min-height: 70vh;
}

.post__head {
    text-align: center;
    padding-top: 3em;
}
#singlepost .published {
    color: rgb(133, 133, 133);
    font-family: 'Inter';
    font-weight: 500;
    font-size: .95em;
}
#singlepost .title {
    font-size: 2em;
    font-family: 'Roboto';
    font-weight: 700;
    padding: .2em;
}

#singlepost .post__head {
    margin-bottom: 2em;
}

.labels {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: center;
}

.label {
    background-color: #ddd;
    padding: .5em 1em;
    width: fit-content;
    font-size: .9em;
    font-weight: 700;
} 

.share {
    padding: 1em;
    margin-top: .5em;
}
.share a {
    padding: .5em 1em;
    text-decoration: none;
    background-color: rgb(76, 97, 214);
    color: #fff;
    font-size: .85em;
    
    font-family: 'Cerebri Sans';
}

.post__body {
    display: flex;
    grid-template-columns: 70% 30%;
    grid-gap: 20px;
    padding-bottom: 2em;
    max-width: 100%;
    flex-wrap: wrap;
}
.post__widgets {
    background-color: rgb(39, 39, 39);
    color: #fff;
    align-content: center;
    justify-content: center;
    text-align: center;
}
.post__content {
   text-align: justify;
   
}

.post__content img {
    max-width: 100% !important;
    height: auto !important;
}
.post__content a {
    color: rgb(16, 190, 16);
    text-decoration: none;
}
.post__content p {
    margin-top: 1em;
    font-family: 'Inter';
    font-size: .95em;
}



@media (max-width: 1000px) {
    .post__body {
        grid-template-columns: 60% 40%;
    }
}
@media (max-width: 1000px) {
    .post__body {
        grid-template-columns: 70% 30%;
    }
}

@media (max-width: 768px) {
    .post__body {
        grid-template-columns: 100%;
    }
}