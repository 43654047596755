#docs {
    min-height: 50vh;
}

#docs .docs__head {
    margin-top: 3em;
}
#docs .docs__head .title {
    font-size: 1.5em;    
}
#docs .docs__head .title:after {
    background: none repeat scroll 0 0 rgb(43, 128, 43);
    bottom: -10px;
    content: "";
    display: block;
    height: 3px;
    position: relative;
    width: 100px;
 }
 #docs .docs__content {
     margin-top: 3em;
     min-height: 30vh;


     
 }

#docs .docs__content>.docs__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

}
#docs .docs__wrapper {
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1em;
}

@media (max-width: 768px) {
    #docs .docs__wrapper {
        grid-template-columns: 100%;
    }
}
#docs .docs__filter {
    margin: 1em auto;
}
a.doc:focus {
    background-color: rgb(219, 219, 219);
}
a.doc:hover {
    background-color: rgb(231, 231, 231);
}
a.doc:focus .doc>p {
    text-decoration: none;
}
.doc {
    
    padding: .8em 2em;
    background-color: rgb(255, 255, 255);
    height: fit-content;
    box-shadow: 0px 0px 3px rgb(29 58 83 / 15%) !important;
    text-decoration: none;
    border-radius: .5em;
    transition: .2s;
}
.doc:hover {
    box-shadow: 0px 0px 10px rgba(13, 107, 248, 0.4) !important;
}

.doc p {
    font-size: .9em;
    color: #222;
}
.docs__filter>h3 {
    margin: 1em auto;
}
.docs__filter {
    padding-bottom: 2em;
}

.doc__content h2 {
    font-family: 'Roboto';
    font-size: 1.2em;
    margin-bottom: .5em;
}
.doc__content p {
    font-family: 'Heebo';
    font-size: .95em;
    text-align: justify;
    margin-bottom: .35em;
}
.doc__content {
    margin-bottom: 2em;
}
.doc__content {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 100%;
    grid-gap: 1em;
}
.doc__content > a {
    text-decoration: none;
}
.doc p {
    margin: 0;
    font-weight: 500;
    font-family: 'Roboto';
    color: rgb(44, 44, 44);
}
.doc {
    display: flex;
    align-items: center;
}
.doc__icon {
    margin-right: 10px;
    height: 48px;
    color: rgb(66, 66, 66);
}
@media (max-width: 777px) {
    .doc__content {
        grid-template-columns: 100%
    }
}