#login__container {
    min-height: 60vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.login__box h1 {
   font-size: 1.5em;
}
.login__box   {
    box-shadow: 0px 0px 40px rgb(29 58 83 / 15%) !important;
    padding: 2em 3em;
    width: 50%;
    min-height: 30vh;
    transition: .2s;
}

.input__container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
}
.login_input {
    display: block;
    margin-bottom: 1em;
    padding: .78em;
    outline: none;
    border: 1px solid rgb(236, 236, 236);
    border-radius: .2em;
    transition: .2s;
    font-size: .95em;
}
.login_input:hover {
    border-color: rgb(92, 96, 139);
}
.login_input:focus {
    border-color: rgb(60, 63, 245);
}
.input__container>button {
    padding: 1em;
    background-color: rgb(0, 78, 223);
    border: none;
    color: #fff;
    font-weight: 700;
    cursor: pointer;
    border-radius: .2em;
    font-size: .95em;
}
.input__container>button:hover {
    background-color: rgb(0, 47, 134);
}

@media (max-width: 1200px) {
    .login__box {
        width: 60%;
    }
}

@media (max-width: 500px) {
    .login__box {
        width: 100%;
        padding: 2em 1em;
    }
}