#post {
    min-height: 50vh;
}
#post h2  {
    font-family: 'Inter';
    margin-top: 2em;
    text-align: center;
    font-size: 1.7em;
}
#post .desc {
    font-family: 'Cerebri Sans';
}

#post .posts-container {
    margin-top: 2em;
    /*display: flex;
    flex-wrap: wrap;*/

    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 1em;
    margin-bottom: 2em;
}

#post .post {
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 1em auto;
    width: 30%;
    cursor: pointer;
    transition: .4s;
    border-radius: .3em;
    width: 20rem;

    display: flex;
    flex-direction: column;
}

#post .post:hover {
    box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;


}
#post .post .image {
    overflow: hidden;
    position: relative;
    display: inline-block;
}
#post .post .image>img {

    border-top-left-radius: .3em;
    border-top-right-radius: .3em;

 
    width: 13rem;
    max-width: 300px;
    display: block;
    margin: auto;
    padding: 1em 2em;
    
    
    
}
#post .post .content {
    padding: 1rem 2rem 0 2rem;
    text-align: justify;
    min-height: 3em;
    
}
#post .post .content .title {
    font-weight: bold;
    font-size: 1.2em;
    font-family: 'Roboto';
    color: #222;

}

#post .post .content>p {
    font-size: .9em;
    color: rgb(61, 61, 61);
    font-family: 'Cerebri Sans';
}
#post .post .info {
  
   align-self: flex-start;
   padding: .2rem 2rem 1rem;
   margin-top: auto;
}
#post .post .info>.time {
    display: flex;
    align-content: center;
    justify-content: flex-end;
    font-size: .8em;
    text-align: right;
    color:rgb(121, 121, 121); 
    margin-top: .5em;
}
#post .post .info>.time>.post-icon {
    color: rgb(28, 119, 255, .9);
    font-size: 16px;
    
    margin-right: .2em;
}



@media (max-width: 1270px) {
    #post .posts-container {
        grid-template-columns: 50% 50%;
    }
    #post .post {
        width: 80%
    }
    #post .post .image>img {
        width: 100%;
    }
    
}

@media (max-width: 840px) {
    #post .posts-container {
        grid-template-columns: 100%;
    }
    #post .post {
        width: 100%
    }
    
}

@media (max-width: 440px) {
    #post .post {
        width: 90%;
    }
    
}