#notfound {
    min-height: 63vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#notfound .url>a {
    text-decoration: none;
    color: #aaa;
    margin-bottom: 1em;
    
}
#notfound .url>a:hover {
    text-decoration: underline;
}
#notfound .title {
    font-family: 'Inter';
    font-size: 1.5em;
    font-weight: 700;
    margin: .5em;
}
#notfound .msg {
    color: rgb(175, 175, 175);
    font-family: 'Cerebri Sans';
}