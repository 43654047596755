.news__detail {
    background-color: rgb(255, 255, 255);
    padding: 1em;
    display: flex;
}
.news__detail>a {
    text-decoration: none;
    margin-right: 10px;
    padding: .6em .8em;
    background-color: #222;
    color: #fff;
    font-size: .85em;
    border-radius: .25em;
    height: fit-content;
    font-weight: 500;
    font-family: 'Roboto';
}

.author {
    background-color: rgba(0, 17, 255, 0.288);
    padding: .25em .5em;
    font-weight: 600;
    border-radius: .25em;
}

.bg-primary {
    background-color: rgb(58, 79, 202) !important;
    transition: .2s all;
}
.bg-primary:hover {
    background-color: rgb(34, 56, 180) !important;
}
.bg-warning {
    background-color: rgb(255, 166, 0) !important;
    color: #111 !important;
}
.bg-success {
    background-color: rgb(70, 224, 121) !important;
    color: #111 !important;
}

.bg-danger {
    background-color: rgb(134, 49, 89) !important;
}
button.btn {
    outline: none;
    box-shadow: none;
    cursor: pointer;
    border: none;
    
}
.btn {
    text-decoration: none;
    margin-right: 10px;
    padding: .6em .8em;
    color: #fff;
    font-size: .85em;
    border-radius: .25em;
    display: flex;
    align-items: center;
    width: fit-content;
    font-weight: 600;
}
.btn-icon {
    font-size: 18px;
    margin-right: 5px;
}



#create-news-form {
    margin: 1em 0;
}
.form-control {
    padding: .85em 1em;
    width: 100%;
    border: 1px solid rgba(168, 168, 168, 0.39);
    outline: none;
    transition: .2s all;
    border-radius: .25em;
    font-family: 'Inter';
    font-size: 1em;
}
.form-control:hover {
    border-color: rgba(117, 117, 117, 0.699)
}
.form-control:focus {
    border-color: rgb(57, 57, 175);
}

.create__news {
    width: 100%;
    display: grid;
    grid-template-columns: 75% 25%;
    grid-gap: 1em;
}

@media (max-width: 1200px) {
    .create__news {
        grid-template-columns: 70% 30%;
    }
}
@media (max-width: 1050px) {
    .create__news {
        grid-template-columns: 100%;
    }
}

.image__upload__container {
    padding: 1.5em;
    background-color: rgb(236, 236, 236);
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: .2s all;
    border-radius: .25em;
}
.image__upload__container:hover {
    box-shadow: 0px 0px 10px rgb(29 58 83 / 15%) !important;
}
.image__upload__container>.upload__icon {
    font-size: 30px;
}
.image__upload__container>p {
    margin-top: 1em;
    font-size: .9em;
    font-weight: 600;
}

.preview-container {
    display: flex;
    flex-wrap: wrap;
}
.preview-container>img {
    margin: .5em;
    max-height: 200px;
    max-width: 10rem;
}
.form-sm {
    font-size: .85em;
  }