#language__modal {
    display: none;
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.445);
    align-items: center;
    justify-content: center;
    transition: all .2s;
}
#language__modal.show__lang {
    display: flex;
}
#language__modal .modal__content {
    width: 40%;
    background-color: rgb(250, 250, 250);
    /*background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);*/
    padding: 2.2em 3em;
    border-radius: .5em;
    border: none;
    font-family: 'Inter';
    
}
#language__modal .modal__head {
    display: flex;
    width: 100%;
    align-items: center;
}
#language__modal .modal__head>.close {
    margin-left: auto;
    font-size: 32px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all .2s;
}
#language__modal .modal__head>.close:hover {
    background-color: rgba(44, 44, 44, 0.178);
}
#language__modal .modal__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30vh;
}
.langs__container {
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    grid-gap: .5em;
    margin: 1em 0;
}
.language {
    padding: 1em;
    background-color: rgb(255, 255, 255);
    /*background-image: linear-gradient(to right, #37ecba 0%, #72afd3 100%);*/
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.041) 0px 1px 4px;
    transition: all .3s;
    cursor: pointer;
}
.language:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.language>img {
    width: 128px;
    height: 80px;
    margin-bottom: 1em;
}
#language__modal .choose {
    font-family: 'Heebo';
    color: rgb(61, 61, 61);
    font-weight: 600;
}
@media (max-width: 1400px) {
    #language__modal .modal__content {
        width: 60%;
    }
}
@media (max-width: 1100px) {
    #language__modal .modal__content {
        width: 70%;
    }
}
@media (max-width: 860px) {
    #language__modal .modal__content {
        width: 80%;
    }
}
@media (max-width: 600px) {
    #language__modal .modal__content {
        width: 95%;
    }
    .langs__container {
        grid-template-columns: 100%;
    }

}

#language__modal .welcome {
    margin: .45em auto;
    font-size: 1.2em;
}
#language__modal .welcome:last-child {
    margin-bottom: 2em;
}
#language__modal .welcome>img {
    height: 18px;
    margin-right: 10px;
}

#language__modal .choose {
    margin-top: .5em;
}

hr.sep {
    margin: 2em auto;
    width: 15%;
    border: 1px solid rgb(3, 82, 10);
}