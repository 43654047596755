#doc .site__content p {
    font-size: .98em;
}
.doc__filter {
    margin: 1em 1em;
    margin-bottom: 2em;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: .5em;
    padding: 2em 2em;
    box-shadow: 0px 0px 40px rgb(29 58 83 / 15%) !important;
}
#doc .site__head h1 {
    margin: 0;
}
#doc .site__head {
    background-color: #F5F7F9;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 2em;
}

#doc .select {
    font-size: .9em;
}
#doc label {
    font-size: .95em;
    padding-bottom: .5em;
}