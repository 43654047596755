.text-center {
    text-align: center;
}
.flex {
    display: flex;
    flex-wrap: wrap;
}
.flex-row {
    flex-direction: row;
}
.flex-column {
    flex-direction: column;
}

/* width */
::-webkit-scrollbar {
    width: 8.5px;
}
  
  /* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
  /* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(224, 224, 224); 
}
  
  /* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(90, 90, 90); 
}


.site__head {
    padding-top: 2em;
    padding-bottom: 1em;
    
    width: 100%;
}
.site__title {
    font-size: 1.8em;
    color: rgb(29, 29, 29);
    padding: 1em 0;
    font-family: 'Inter';
  
}

.site__container {
  min-height: 50vh;
  
  padding-bottom: 3em;
  display: grid;
 
}

.site__content p {
  margin-bottom: .5em;
  text-align: justify;
  font-size: .98em;
  line-height: 1.5em;
  font-family: 'Inter';
}

.mb-4 {
  margin-bottom: 2em;
}
.mb-2 {
  margin-bottom: 1em;
}

.indent {
  text-indent: 2em;
}

::selection {
  background-color: rgba(21, 255, 79, 0.39); /*rgba(21, 103, 255, 0.39);*/
}

.error-message {
  background-color: rgba(255, 38, 0, 0.616);
  margin: 1em auto;
  padding: 1em;
}
.error-message>p {
  margin: 0;
  padding: 0;
  font-size: .9em;
  font-weight: 600;
}

.img-responsive {
  max-width: 100%;
  height: auto;
}


.container-fluid {
  width: 100%;
  margin: auto;
  padding: .5em 1em;
}

@media (max-width: 1100px) {
  .container-fluid {
    width: 100%;
  }
}
 .rdt_TableCol {
  background-color: rgb(204, 216, 255);
}
#hirek .rdt_TableCol, #home .rdt_TableCol {
  background-color: rgb(255, 255, 255);

}
.lnndaO, .bigAlm {
  overflow: visible !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}
.table__container {

  max-width: 60vw;
  
}

@media (max-width: 1700px) {
  .table__container {
    max-width: 72vw;
  }
}
@media (max-width: 1400px) {
  .table__container {
    max-width: 85vw;
  }
}
@media (max-width: 1100px) {
  .table__container {
    max-width: 92vw;
  }
}

.intro {
  margin-bottom: 2em;
}

.pava [data-column-id="6"].rdt_TableCol, 
.pava [data-column-id="7"].rdt_TableCol, 
.pava [data-column-id="8"].rdt_TableCol,
.pava [data-column-id="9"].rdt_TableCol, 
.pava [data-column-id="10"].rdt_TableCol, 
.pava [data-column-id="11"].rdt_TableCol  {
  background-color: rgb(255, 115, 0);
}


.pava [data-column-id="12"].rdt_TableCol, 
.pava [data-column-id="13"].rdt_TableCol, 
.pava [data-column-id="14"].rdt_TableCol,
.pava [data-column-id="15"].rdt_TableCol, 
.pava [data-column-id="16"].rdt_TableCol {
  background-color: rgb(196, 196, 196);
}

.pava [data-column-id="17"].rdt_TableCol, 
.pava [data-column-id="18"].rdt_TableCol, 
.pava [data-column-id="19"].rdt_TableCol,
.pava [data-column-id="20"].rdt_TableCol, 
.pava [data-column-id="21"].rdt_TableCol, 
.pava [data-column-id="22"].rdt_TableCol {
  background-color: rgb(255, 97, 97);
}

.pava [data-column-id="23"].rdt_TableCol, 
.pava [data-column-id="24"].rdt_TableCol, 
.pava [data-column-id="25"].rdt_TableCol,
.pava [data-column-id="26"].rdt_TableCol {
  background-color: #70ff83;
}

.pava [data-column-id="27"].rdt_TableCol, 
.pava [data-column-id="28"].rdt_TableCol,
.pava [data-column-id="29"].rdt_TableCol, 
.pava [data-column-id="30"].rdt_TableCol, 
.pava [data-column-id="31"].rdt_TableCol,  
.pava [data-column-id="32"].rdt_TableCol  {
  background-color: rgb(115, 164, 255);
}
/* -------------------------------------  */

.pava1 [data-column-id="4"].rdt_TableCol,
.pava1 [data-column-id="5"].rdt_TableCol {
  background-color: rgb(255, 115, 0);
}

.pava1 [data-column-id="6"].rdt_TableCol,
.pava1 [data-column-id="7"].rdt_TableCol {
  background-color: rgb(196, 196, 196);

}

.pava1 [data-column-id="8"].rdt_TableCol,
.pava1 [data-column-id="9"].rdt_TableCol {
  background-color: rgb(255, 97, 97);
}

.pava1 [data-column-id="10"].rdt_TableCol,
.pava1 [data-column-id="11"].rdt_TableCol {
  background-color: #70ff83;
}

.pava1 [data-column-id="12"].rdt_TableCol,
.pava1 [data-column-id="13"].rdt_TableCol {
  background-color: rgb(115, 164, 255);
}


/* -------------------------------------  */

.pava2 [data-column-id="6"].rdt_TableCol,
.pava2 [data-column-id="7"].rdt_TableCol {
  background-color: rgb(255, 115, 0);
}

.pava2 [data-column-id="8"].rdt_TableCol,
.pava2 [data-column-id="9"].rdt_TableCol {
  background-color: rgb(196, 196, 196);

}

.pava2 [data-column-id="10"].rdt_TableCol,
.pava2 [data-column-id="11"].rdt_TableCol {
  background-color: rgb(255, 97, 97);
}

.pava2 [data-column-id="12"].rdt_TableCol,
.pava2 [data-column-id="13"].rdt_TableCol {
  background-color: #70ff83;
}

#doc.pava2 [data-column-id="14"].rdt_TableCol,
#doc.pava2 [data-column-id="15"].rdt_TableCol {
  background-color: rgb(115, 164, 255);
}


.fullp {
  text-align: left !important;
  overflow: visible !important;
  white-space: normal !important;
  word-wrap: break-word !important;
}


.viewer-toolbar-right {
  width: 0 !important;
  display: none !important;
}