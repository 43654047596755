#videos {
    min-height: 63vh;
}

#videos .videos__head {
    padding-top: 2em;
    padding-bottom: 2em;
    /*background:  linear-gradient(rgb(36, 41, 45,1), rgb(2, 2, 2));*/
    background-color: #111111;
    width: 100%;
}

#videos .videos__head h1 {
    font-size: 2em;
    color: rgb(250, 250, 250);
    padding: 1em 0;
}

#videos .videos__head .title:after {
    background: none repeat scroll 0 0 rgb(112, 162, 255);
    bottom: -10px;
    content: "";
    display: block;
    height: 3px;
    position: relative;
    width: 50px;
 }

#videos .videos__content {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 65% 35%;
    padding-top: 2em;
    padding-bottom: 2em;
}

@media (max-width: 830px) {
    #videos .videos__content {
        grid-template-columns: 50% 45%;
    }
    #videos .vid {
        flex-direction: column;
    }
    .vid .vid__img>img {
        width: 100%;
    }
}
@media (max-width: 600px) {
    #videos .videos__content {
        grid-template-columns: 100%;
    }
    #videos .vid {
        flex-direction: column;
    }
    .vid .vid__img>img {
        width: 100%;
        max-width: 100%;
        display: block;
        margin: auto;
    }
    .vid__content .vid__actions {
        flex-direction: column;
    }
    .vid__actions>.vid__author {
        margin-bottom: 1em;
    }
}
#videos .videos__container {
    display: flex;
    flex-direction: column;
}
 #videos .vid {
     display: flex;
     padding: 1em;
     box-shadow: 0px 0px 40px rgb(29 58 83 / 15%) !important;
     border-radius: .4em;
     margin-bottom: 1em;
 }

 .vid .vid__img>img {
     
     max-width: 180px;
     border-radius: .4em;
 }
 .vid .vid__content {
     padding-left: 1em;
     padding-top: .3em;
     width: 100%;
     padding-right: 1em;
     padding-bottom: .3em;
 }
.vid__content .vid__type {
    background-color: #066AC9;
    width: fit-content;
    color: #fefefe;
    padding: .2em .5em;
    font-size: .7em;
    font-weight: 600;
    border-radius: .35em;
}

.vid__content .vid__title {
    font-size: 1.2em;
    font-weight: 700;
    margin-top: .35em;
    margin-bottom: .4em;
    
}
.vid__content .vid__description {
    font-family: 'Inter';
    color: rgb(150, 150, 150);
    font-size: .85em;
}

.vid__content .vid__actions {
    margin-top: 1.2em;
    display: flex;
    justify-content: space-between;
}
.vid__actions .vid__author {
    font-family: 'Inter';
    font-size: .8em;
    align-self: center;
    font-weight: 600;
    color: rgb(27, 27, 27);
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(78, 197, 118, 0.25);
    padding: .3em .5em;
    border-radius: .2em;
    cursor: pointer;
    transition: .2s;
    
}
.vid__actions .vid__author:hover {
    background-color: rgba(78, 197, 118, 0.6);
}
.vid__actions .vid__view {
    align-self: center;
   
}
.view-btn {
    background-color: #24292D;
    padding: .6em .8em;
    color: #fefefe;
    border-radius: .2em;
    font-size: .85em;
    text-decoration: none;
    font-weight: 600;
}
.avatar-icon {
    font-size: 18px;
    margin-right: 3px;
}


/* Filter */

#videos .filters__container {
    display: flex;
    flex-direction: column;
}

#videos .filter {
    box-shadow: 0px 0px 40px rgb(29 58 83 / 15%) !important;
    padding: 1.5em;
    margin-bottom: 1em;
}
.filter h3 {
    font-size: 1.2em;
    color: rgb(26, 26, 26);
    margin-bottom: 1em;
}
.filter-status {
    color: #7e8183;
    font-size: .9em;
}

/*
.category {
    font-size: .9em;
    display: flex;
    align-items: center;
    margin-bottom: .35em;
}
.category>label {
    margin-left: 5px;
    color: #24292D;
}
.category>input {
    height: 16px;
    width: 16px;
    
}*/

/* The container */
.category {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: .9em;
    font-family: 'Roboto';
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .category input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    ;
  }
  
  /* Create a custom checkbox */
  .category .check {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #eee;
    border-radius: .2em;
    transition: all .2s 
  }
  
  /* On mouse-over, add a grey background color */
  .category:hover input ~ .check {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .category input:checked ~ .check {
    background-color: #2159f3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .check:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .category input:checked ~ .check:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .category .check:after {
    left: 7px;
    top: 3px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }


select#eloadok, .select {
    width: 100%;
    font-size: 1em;
    padding: .5em;
    border-color: #dddddd;
    outline: none;
    font-family: 'Roboto';
    color: rgb(53, 53, 53);

}

select#eloadok:focus, .select:focus {
    border-color: #1066a0;
}
select#eloadok:hover, .select:hover {
    border-color: #679abd;
}
select#eloadok:selected, .select:selected {
    border-color: #679abd;
}


.mini-nav {
    display: flex;
    font-size: .9em;
}
.mini-nav>.page {
    text-decoration: none;
    
    color:rgb(112, 162, 255);
    transition: all .2s;
}
.mini-nav>.page:hover {
    text-decoration: underline;
}
.mini-nav>.page.active {
    color: #ddd;
    text-decoration: none;
}
.page-separator {
    color: #ddd;
    margin: 0 5px;
}