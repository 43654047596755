.file {
    padding: 2em 1em;
    padding-bottom: .35em;
    background-color: rgb(243, 243, 243);
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1em;
}
.file__icon>svg {
    font-size: 50px;
    color: rgb(85, 85, 85);
}
.file__name {
    font-size: .9em;
    word-wrap: break-word;
    padding: 0 1em;
    justify-self: flex-end;
    margin-top: auto;
    padding-bottom: 1em;
    text-align: center;
    overflow-wrap: anywhere;
}
.files {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-gap: 1em;
}

@media (max-width: 1000px) {
    .files {
        grid-template-columns: 100%;
    }
}

.file__upload__pdf {

}
.upload {
    background-color: rgb(10, 10, 10);
    color: rgb(175, 175, 175);
    cursor: pointer;
    transition: all .2s;
}
.upload:hover {
    background-color: rgb(24, 24, 24);
}
.file__actions .bg-remove {
    background-color: rgb(255, 74, 98);
    font-weight: 700;
    transition: all .2s;
}
.file__actions .btn {
  
    width: auto;

}
.file__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
}
.bg-remove:hover {
    background-color: red;
}
.file__actions svg {
    font-size: 16px;
    font-weight: 700;
}

.doc_icon_upload>img {
    height: 36px; 
    width: 36px;
    margin: 1em 0;
    display: block;
}

.upload_btn_container {
    margin-bottom: 1em;
}
.upload_btn_container>button {
    margin-bottom: 0;
}