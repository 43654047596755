#sidebar .sidebar__head > img {
    width: 80px;
    display: block;
    margin: 1em auto 2em auto;
}

.sidebar__pages>.sidebar__section {
    border-top: 1px solid rgb(99, 99, 99);
}

.section__title {
    color: rgb(167, 167, 167);
    font-size: .85em;
    margin: 1em 1.5em .5em 1.5em;
    font-weight: 400;
}
.sidebar__section {
    font-family: 'Inter';
}
.sidebar__pages {
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
}
.sidebar__pages .page {
    margin: .5em 1em;
    padding: .76em 1em; 
    text-decoration: none;
    color: rgb(49, 49, 49);
    font-family: 'Heebo';
    /*background-color: rgba(68, 95, 184, 0.116);*/
    display: block;
    border-radius: .25em;
    font-size: 1em;
    font-weight: 600;
    display: flex;
    align-items: center;

}
.sidebar__pages .page__icon {
    margin-right: 10px;
}
.sidebar__pages .page:hover {
    background-color: rgb(236, 236, 236);
}
.sidebar__pages .page.active__page {
    background-color: black;
    color: #fff;
}

.logout-btn {
    background-color: rgb(231, 208, 208);
    border: 0;
    padding: .85em 1em;
    font-weight: 600;
    font-family: 'Inter';
    font-size: .95em;
    
    cursor: pointer;
    display: flex;
    margin: 1em 2em;
    align-self: center;
    border-radius: .25em;
    
}
button>.page__icon {
    font-size: 1.2em;
}

.username {
    font-weight: 600;
    color:rgb(119, 119, 119);
    text-align: center;
    margin-top: 2em;
    font-size: .8em;
    display: flex;
    align-items: center;
    justify-content: center;
}