.dashboard__page {
    display: grid;
    grid-template-columns: 15% 85%;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
}
#sidebar {
    min-height: 100vh;
    background-color: #ffffff;
    border-right: 1px solid rgba(236, 236, 236, 0.397);
    width: 100%;
    color: #fff;
    
}
.dashboard__content {
    background-color: #F9F9F9;
    padding: 2em;
}
.dashboard__content>h1 {
    margin-bottom: 2em;
}


@media (max-width: 1500px) {
    .dashboard__page {
        grid-template-columns: 20% 80%;
    }
}

@media (max-width: 1100px) {
    .dashboard__page {
        grid-template-columns: 30% 70%;
    }
}
@media (max-width: 700px) {
    .dashboard__page {
        grid-template-columns: 100%;
    }
    #sidebar {
        min-height: 1vh;
    }
}


.card {
    background-color: #fff;
    padding: 2em 2em;
    box-shadow: 0px 0px 10px rgba(29, 58, 83, 0.014) !important;
    border-radius: .5em;
    margin: 1em auto;
    width: 100%;
}
.card__head h3 {
    font-size: 1.4em;
    margin-bottom: 1em;
}
.card__body {
    font-family: 'Inter';
    text-align: justify;
}




.doc_icon_upload {
    margin: 1em 0;
}