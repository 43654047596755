.widget {
    box-shadow: 0px 0px 40px rgb(29 58 83 / 15%) !important;
    border-radius: .4em;
    margin-bottom: 1em;
    padding: 1em 2em;
    background-color: #fff;
}

.widget h3 {
    font-size: 1.2em;
    color: rgb(26, 26, 26);
    margin-bottom: .5em;
}
.widget__content {
    font-size: .95em;
    color: rgb(97, 97, 97);
}