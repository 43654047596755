.smart-projekt {
    width: 100%;
    text-align: center;
    align-items: center;
    margin-bottom: 2em;
}
.smart-projekt>p {
    text-align: center;
    width: 60%;
    display: block;
    margin: auto;
    font-weight: 600;
    margin-bottom: .5em;
}