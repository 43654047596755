.video__head {
    background-color: #F5F7F9;
    padding-top: 4em;
    padding-bottom: 4em;
}

.video__type {
    background-color: #066AC9;
    width: fit-content;
    padding: .5em 1em;
    border-radius: .4em;
    color: #fff;
    font-family: 'Inter';
    font-weight: 600;
}
h1.video__title {
    margin-top: .5em;
    margin-bottom: .5em;
    font-family: 'Roboto';
    max-width: 70%;
}

.video__description {
    font-family: 'Inter';
    color: rgb(122, 122, 122);
    font-size: .9em;
}

.video__content {
    display: grid;
    grid-template-columns: 100%;
    min-height: 50vh;
    padding: 3em 0;
    grid-gap: 1em;
}

.video__section {
    box-shadow: 0px 0px 40px rgb(29 58 83 / 15%) !important;
    border-radius: .4em;
    
}
.video__header {
    padding: 1em 2em;
    border-bottom: 1px solid #ddd;
}
.vid__content {
    padding: 1em 2em;
}
.vid__text {
    padding: 1em 0;
}

.vid__text>h3 {
    margin-top: 1em;
    margin-bottom: .5em;
    color: rgba(19, 19, 19, 0.933);
}
.vid__text>p {
    font-size: .9em;
    text-align: justify;
    margin-bottom: .5em;
}

/*

@media (max-width: 1017px) {
    .video__content {
        grid-template-columns: 60% 40%;
    }
}
@media (max-width: 768px) {
    .video__content {
        grid-template-columns: 100%;
    }
    .vid__content {
        padding: 1em .7em;
    }
}*/

.video__infos {
    display: flex;
    margin-top: .8em;
}
.video__infos>.info {
    font-size: .95em;
    display: flex;
    align-items: center;
    margin-right: 1em;
    font-weight: 600;
}
.info-icon {
    margin-right: 5px;
    color: rgb(67, 163, 91);
}


.csatolmanyok {
    display: flex;
    flex-direction: column;
}
.csatolmany {
    margin: .5em 0;
    background-color: rgb(248, 248, 248);
    padding: .5em 1em;
    display: flex;
    width: 100%;
    align-items: center;
    word-wrap: break-all;
    flex-wrap: wrap;
}
.csatolmany p {
    word-wrap: break-word;
}
.csatolmany a {
    text-decoration: none;
    color: rgb(52, 61, 194);
    font-size: .9em;
    font-family: 'Inter';
    
}
.csatolmany a.btn {
    margin-left: auto;
    color: #fff;
    font-weight: 500;
}

.iframe-video {
    width: 100%;
    height: 600px
}

@media (max-width: 1024px) {
    .iframe-video {
        height: 400px
    }
}

@media (max-width: 600px) {
    .iframe-video {
        height: 200px
    }
    .vid__content {
        padding: .5em;
    }
}