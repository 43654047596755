#footer {
    padding: 1em;
    box-shadow: 0px 0px 5px rgba(29, 58, 83, 0.103) !important;
    background-color: #F5F7F9;
    /*background-color: #1A1C27;*/
    bottom: 0;
    margin-top: auto;
}
#bottom-footer {
    background-color: #d8dee49c/*#e7e7ee;*/
    /*background-color: #222533;*/
}
#bottom-footer .bottom {
    display: flex;
    align-items: center;
    
    
}
#bottom-footer  .bottom>p {
    font-size: .9em;
    color: rgb(64, 66, 73);
    padding: 1.5em;
    /*color: #ddd;*/
    font-family: 'Inter';
}
#bottom-footer  .bottom>img {
    margin-left: auto;
    
}

#footer .content {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: 25% 50% 25%;

}

@media (max-width: 1155px) {
    #footer .content {
        grid-template-columns: 50% 50%;
    }
}
@media (max-width: 600px) {
    #footer .content {
        grid-template-columns: 100%;
    }
}

@media (max-width: 450px) {
    .partner {
        flex-direction: column;
    }
}
#footer .content>img {
    width: 18rem;
}

#footer .section {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding: 1em;
    
    
}
#footer .section>p.desc {
    text-align: justify;
    font-weight: 700;
    font-size: .95em;
    font-family: 'Roboto';
}
#footer .section>p {
    font-size: .9em;
    margin-top: .5em;
    color: rgb(46, 46, 46);
    /*color: rgb(126, 126, 126);*/
    font-family: 'Inter';
}

.section p>a {
    text-decoration: none;
    color: rgb(36, 131, 255);
    /*color: rgb(158, 158, 158);*/
    
}
.section p>a:hover {
    text-decoration: underline;
    
    
}
.section>h3 {
    color:rgb(73, 73, 73);
    margin-bottom: .5em;
}
@media screen and (max-width: 768px) {
    #bottom-footer .bottom {
        flex-direction: column;
    }

    #bottom-footer  .bottom>img {
        margin-left: 0;

    }
}

.partner {
    color: #313131;
   
    margin: .5em auto;
    font-family: 'Cerebri Sans';
    font-weight: 600;
    display: flex;
    text-align: justify;
    align-items: center;
    width: 100%;
    
}
.partner__nev {
    font-size: .9em;
    font-family: 'Heebo';

}
.partner__cim {
    color: rgb(126, 126, 126);
    font-weight: 400;
    margin-top: .25em;
    font-size: .85em;

}
.partner__web {
    text-decoration: none;
    color: rgb(46, 112, 255);
    font-weight: 400;
    font-size: .95em;

}

.partner__logo>img {
    width:  80px;
    display: block;
    margin: auto;
    margin-bottom: .25em;
    margin-right: 1em;

    
}


.section h4 {
    
    margin-bottom: .5em;
    font-weight: 500;
    font-size: .9em;
    color: rgb(43, 43, 43);
    
}