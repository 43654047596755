#navbar {
    background-color: rgb(255, 255, 255);
    width: 100%;
    box-shadow: rgba(182, 182, 187, 0.1) 0px 7px 29px 0px;
    font-family: 'Inter';
    

   
}
.navbar-container {
    margin-left: auto;
    margin-right: auto;
    padding: 1em 15px;
    display: flex;
    justify-content: space-between;
   
    align-items: center;
}

  
@media (min-width: 840px) {
    .navbar-container {
      width: 750px;
    }
}
  
@media (min-width: 1000px) {
    .navbar-container {
      width: 970px;
    }
}
  
@media (min-width: 1226px) {
    .navbar-container {
      width: 1170px;
    }
}

#logo {
    display: flex;
    align-items: center;
}
#logo img {
    height: 64px;
}

.nav__list {
    display: flex;
    align-items: center;
}

.nav__item {
    margin: 0 2px;
    list-style: none;
    position: relative;
}

.nav__link {
    padding: 15px 10px;
    font-size: .9rem;
    font-weight: 500;
    color: #222;
    text-decoration: none;
    transition: .1s;
    align-items: center;
    display: flex;
    border-radius: .4em;
}

.nav__link:hover {
    color: rgb(32, 130, 209);
    background-color: rgba(32, 130, 209, .1);
}

.toggle__nav {
    display: none;
    cursor: pointer;
}

.close__nav {
    display: none;
    cursor: pointer;
}

@media screen and (max-width: 854px) {
    #navbar {
        height: 64px;
        justify-content: space-between;
        padding: 0 28px;
    }

    #logo img {
        height: 40px;
    }
    .toggle__nav {
        display: inline;
        font-size: 24px;
    }
    .close__nav {
        position: absolute;
        right: 24px;
        display: block;
        font-size: 24px;
      
        border-radius: 50%;
        color:rgb(255, 255, 255);
        transition: .2s;
        
    }
    .nav {
        position: fixed;
        top: 0;
        right: -100%;
        background-color: rgb(34, 34, 34, .98);
        width: 100%;
        height: 100vh;
        padding: 24px 0;
        z-index: 100;
        transition: 0.5s;
        border-radius: 0 0 0 0;
    }

    .nav__list {
        display: flex;
        flex-direction: column;
    }
    .nav__item {
        margin: .5rem 0;

    }
    .nav__link {
        color:rgb(255, 255, 255);
    }
    .dropdown__menu .nav__link {
        color:rgb(41, 41, 41);
    }

    .show {
        right: 0;
    }

}

.toggle__nav:hover {
    color: rgb(32, 130, 209);
    
}

.close__nav:hover {
    
    background-color: rgba(32, 130, 209, 1);
}

.dropdown__menu ul {
    list-style: none;
    display: block;
    flex-direction: column;
    width: 180px;
    margin: 10px;
    
}
.dropdown__menu li {
    width: 180px;
}
.dropdown__menu li>a:hover {
    background-color: rgba(32, 130, 209, .1);
  
}

.dropdown__menu li>a.nav__link {
    font-size: .8em;
    border-radius: .4em;
    padding: 1em;
}

.dropdown__menu {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    z-index: 101;
    border-radius: .4em;

}

.dropdown__menu {
    display: none;
}

.nav__list .nav__item:hover .dropdown__menu {
    display: block;
}



#upperNav {
    background-color: rgb(252, 252, 252);
    color: rgb(68, 68, 68);
    padding: .5em;

}

.belepes__container {
    margin-left: auto;

}
.belepes {
    text-decoration: none;
    background-color:rgb(32, 79, 209);
    color: #fff;
    padding: .5em 1em;
    font-size: .9em;
    border-radius: .25em;
    align-content: center;
    justify-content: center;
    display: flex;
    font-weight: 600;
}
.belepes:hover {
    background-color:rgb(31, 70, 177);
}

.language__select {
    text-decoration: none;
    background-image: linear-gradient(to left bottom, #0053cf, #007eed, #0098cb, #00ab7c, #00b500);
    color: #fff;
    padding: .5em 1em;
    font-size: .9em;
    border-radius: .25em;
    align-content: center;
    justify-content: center;
    display: flex;
    font-weight: bold;
}

