.col-6 {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1em;
}

.attachments {
    display: flex;
    flex-direction: column;
    padding: .5em;
    min-width: 30%;
}
.attachment {
    background-color: #fefefe;
    padding: .5em 1em;
    margin: .2em 0;
    box-shadow: 0px 0px 5px rgba(112, 131, 148, 0.2)!important;
    width: 100%;
    border-radius: .25em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    word-break: break-word;
}
.attachment>a.filename {
    font-weight: 600;
    text-decoration: none; 
    font-size: .8em;
    color: rgb(68, 104, 202);
    
}
.attachment>.btn {
    margin-left: auto;
}
