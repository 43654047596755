
@font-face {
  font-family: 'Cerebri Sans';
  src: url('./Assets/fonts/Cerebri\ Sans\ Book.ttf');
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

html, body {
  height: 100vh;
  width: 100%;
  font-family: 'Roboto', sans-serif;
}

/*
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.inner-container {
  display: flex;
  align-items: center;

}
*/





